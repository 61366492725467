<ng-container>
  <h1 [class.dialog-title]="isDialogVerify">
    {{ "APP.AUTH.ENTER_EMAIL_CODE" | translate }}
  </h1>
  <div class="subheader subheader-sm">
    {{ "APP.AUTH.ENTER_EMAIL_CODE_SUBHEADER" | translate }}
    <span>{{ email }}</span>
  </div>

  <div class="form-380">
    <form class="form code-input-form">
      <app-code-input
        #codeInput
        class="code-input"
        [codeLength]="6"
        [hasError]="isWrongCode"
        (codeChanged)="onCodeChanged($event)"
        (codeCompleted)="onCodeCompleted($event)"
      >
      </app-code-input>

      @if (isWrongCode) {
        <div class="mb-20">
          @if (resendSuccess) {
            <div class="text-success">
              {{ "APP.AUTH.SUCCESS.MAIL_RESENT" | translate }}
            </div>
          } @else {
            <mat-error>
              {{ "APP.AUTH.ERRORS.WRONG_CODE" | translate }}
            </mat-error>
          }
        </div>

        <div
          class="action-btn-container"
          [class.dialog-verify-btn]="isDialogVerify"
        >
          <button
            type="button"
            class="btn btn-primary"
            (click)="resendEmail()"
            [disabled]="resendInProgress || isResendInCooldown"
          >
            {{ "APP.RESEND_MAIL" | translate }}
          </button>
        </div>
      } @else {
        <div class='resend-wrapper'>
          <span class="text-link" (click)="resendEmail()">
            {{ "APP.RESEND" | translate }}
          </span>
          <span class='text-description'>{{ "APP.AUTH.VIA_MAIL" | translate }}</span>
        </div>
      }
      @if (isResendInCooldown) {
        <div class="resend-code-cooldown-info">
          {{ "APP.AUTH.RESEND_CODE_WAIT_1" | translate }}
          {{ timeLeft | countdownFormat }}
          {{ "APP.AUTH.RESEND_CODE_WAIT_2" | translate }}
        </div>
      }
    </form>
  </div>
</ng-container>
